import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import FileUpload from "../From/FileUpload";
import InputWithIcon from "../From/InputWithIcon";
import SelectBox from "../From/SelectBox";
import Button from "../Button";
import { CategoryList, addNewSeries } from "../../../Action/AdminAction";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { AddSeriesSchema } from "../../lib/schema";

const cities = [
  "Popular ",
  "TV shows",
  "male audiences",
  "female audiences",
  "action",
];

const AddSeriesModal = ({ openModal, closeModal, categoryData }) => {
  // const [file, setFile] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [thumbnails, setThumbnails] = useState([]);
  const [bannerImage, setBannerImage] = useState();
  const [loader, setLoader] = useState(false);

  const formik = useFormik({
    initialValues: {
      series_name: "",
      price: "",
      category: "",
      series_desc: "",
    },
    validationSchema: AddSeriesSchema,
    onSubmit: async (values) => {
      setLoader(true);
      const formData = new FormData();
      for (const type in videoFiles) {
        if (typeof videoFiles[type] == "object") {
          formData.append("episode_url", videoFiles[type]);
        }
      }
      for (const type in thumbnails) {
        if (typeof thumbnails[type] == "object") {
          formData.append("episode_thumbnail", thumbnails[type]);
        }
      }
      formData.append("series_image", bannerImage && bannerImage[0]);
      formData.append("series_name", values?.series_name);
      formData.append("price", values?.price);
      formData.append("category", values?.category);
      formData.append("series_desc", values?.series_desc);

      const response = await addNewSeries(formData);

      if (response?.data?.ResponseCode == 1 && response?.data?.Result == true) {
        toast.success(response?.data?.ResponseMsg);
        setLoader(false);
        setBannerImage();
        setThumbnails([]);
        setVideoFiles([]);
        // formik.values.series_name = "";
        // formik.values.price = "";
        // formik.values.category = "";
        // formik.values.series_desc = "";
        formik.resetForm();
        closeModal();
      } else {
        toast.error(response?.data?.ResponseMsg);
        setBannerImage();
        setLoader(false);
        setVideoFiles([]);
        setThumbnails([]);
        formik.resetForm();
        // closeModal();
      }
    },
  });

  const handleVideoChange = async (event) => {
    const files = Array.from(event.target.files);
    const newVideoFiles = [];

    const newThumbnails = [];

    for (const file of files) {
      newVideoFiles.push(file);
      try {
        const thumbnailFile = await createThumbnail(file);
        newThumbnails.push(thumbnailFile);
      } catch (error) {
        console.error("Error creating thumbnail:", error);
      }
    }

    setVideoFiles(newVideoFiles);
    setThumbnails(newThumbnails);
  };

  // generate thumbnail
  const createThumbnail = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = () => {
        video.currentTime = 2; // Capture frame at 10 seconds into the video
        video.onseeked = async () => {
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

          // Convert canvas to Blob
          canvas.toBlob((blob) => {
            if (blob) {
              const thumbnailFile = new File(
                [blob],
                file.name.replace(/\.\w+$/, ".jpg"),
                {
                  type: "image/jpeg",
                  lastModified: new Date().getTime(),
                }
              );
              resolve(thumbnailFile);
            } else {
              reject(new Error("Canvas to Blob conversion failed"));
            }
          }, "image/jpeg");
        };
      };

      video.onerror = (error) => reject(error);
      video.src = URL.createObjectURL(file);
    });
  };

  return (
    <>
      <Modal
        title="Add Series"
        openModal={openModal}
        closeModal={() => {
          closeModal();
          formik.resetForm();
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="px-5 pt-3 pb-5 space-y-4">
            <div className="grid grid-cols-2 gap-4 ">
              <FileUpload
                label="Upload Banner"
                onChange={(e) => {
                  setBannerImage(e.target.files);
                }}
                files={bannerImage}
              />
              <FileUpload
                label="Upload Episodes"
                name="file"
                onChange={handleVideoChange}
                files={videoFiles}
              />
            </div>

            <div className="">
              <InputWithIcon
                placeholder="Series Name"
                name="series_name"
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                touched={formik.touched.series_name}
                error={formik.errors.series_name}
                value={formik.values.series_name}
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <InputWithIcon
                placeholder="Price"
                name="price"
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                touched={formik.touched.price}
                error={formik.errors.price}
                value={formik.values.price}
              />
              <SelectBox
                label="Branch Location"
                name="category"
                defaultOption="Select Category"
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                touched={formik.touched.category}
                error={formik.errors.category}
                value={formik.values.category}
                options={categoryData}
              />
            </div>
            <div className="flex flex-col">
              <textarea
                name="series_desc"
                className={`w-full text-white  rounded-[15px] bg-inputBG shadow-3xl outline-none h-[150px] px-4 py-3 ${
                  formik.errors.series_desc &&
                  formik.touched.series_desc && //handle errors
                  "placeholder:text-red-400 caret-red-400 ring-1 ring-red-400 focus-visible:ring-red-400"
                }`}
                placeholder="Description"
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                values={formik.values.series_desc}
              ></textarea>
              {formik.errors.series_desc && formik.touched.series_desc ? (
                <div className="text-red-500 text-xs mt-1">
                  {formik.errors.series_desc}
                </div>
              ) : null}
            </div>
          </div>
          <div className="border-t border-inputBG w-full px-5 py-4">
            {loader ? (
              // <div className="px-5 py-4">
              <button className="buttonBg w-full text-white text-xl font-semibold rounded-[10px] py-3.5 flex items-center justify-center">
                <svg
                  aria-hidden="true"
                  role="status"
                  class="inline w-6 h-6 me-3 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#838383"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                <span className="text-lg font-medium text-white">
                  {" "}
                  Loading...
                </span>
              </button>
            ) : (
              // </div>
              // <div className="border-t border-inputBG w-full px-5 py-4">
              <Button
                text="Create"
                className="w-full"
                // onClick={handleAddNewSeries}
              />
              // </div>
            )}
            {/* <Button
              text="Create"
              className="w-full"
              // onClick={handleAddNewSeries}
            /> */}
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddSeriesModal;
