import * as Yup from 'yup';

export const AddSeriesSchema = Yup.object().shape({
    series_name: Yup.string().required("Series Name is required"),
    price: Yup.string().required("Price is required"),
    category: Yup.string().required("Category is required"),
    series_desc: Yup.string().required("Description is required")
})

export const PlanSchema = Yup.object().shape({
    coin: Yup.number()
        .required('Coin is required!')
        .test(
            'Is positive?',
            'Coin must be greater than 0!',
            (value) => value > 0
        ),
    price: Yup.number().required('Price is required').test("Is positive?", "Price must be greater than 0!", (value) => value > 0),
})

export const LoginSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is requires")
})

export const forgetPasswordSchema = Yup.object().shape({
    email: Yup.string().required("Email is required")
})

export const resetPasswordSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    newpassword: Yup.string().required("New password is required").oneOf([Yup.ref('password'), null], 'Password does not match')
})

export const categorySchema = Yup.object().shape({
    category: Yup.string().required("Category name is required")
})

export const genreSchema = Yup.object().shape({
    category_name: Yup.string().required("Genre name is required")
})