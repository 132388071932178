import React from "react";
import Layout from "../Layout/Layout";
import { ImageUrl } from "../../../config/ImageUrl";

const data = [
  {
    id: "1",
    seriesName: "The Life You Wanted",
    episode: "20",
    category: "Romantic",
    price: "20 Coins",
    img: `${ImageUrl}assets/images/profile.png`,
  },
  {
    id: "1",
    seriesName: "The Life You Wanted",
    episode: "20",
    category: "Romantic",
    price: "20 Coins",
    img: `${ImageUrl}assets/images/profile.png`,
  },
  {
    id: "1",
    seriesName: "The Life You Wanted",
    episode: "20",
    category: "Romantic",
    price: "20 Coins",
    img: `${ImageUrl}assets/images/profile.png`,
  },
  {
    id: "1",
    seriesName: "The Life You Wanted",
    episode: "20",
    category: "Romantic",
    price: "20 Coins",
    img: `${ImageUrl}assets/images/profile.png`,
  },
  {
    id: "1",
    seriesName: "The Life You Wanted",
    episode: "20",
    category: "Romantic",
    price: "20 Coins",
    img: `${ImageUrl}assets/images/profile.png`,
  },
  {
    id: "1",
    seriesName: "The Life You Wanted",
    episode: "20",
    category: "Romantic",
    price: "20 Coins",
    img: `${ImageUrl}assets/images/profile.png`,
  },
  {
    id: "1",
    seriesName: "The Life You Wanted",
    episode: "20",
    category: "Romantic",
    price: "20 Coins",
    img: `${ImageUrl}assets/images/profile.png`,
  },
];

const DataGrid = () => {
  return (
    <>
      <Layout>
        <div className="relative bg-white">
        <table class=" w-full h-[50px] overflow-auto ">
          <thead className="sticky bottom-0 bg-inputBG shadow-3xl rounded-[10px]">
            <tr className="text-start text-textColor text-base font-medium py-10">
              <th className="text-start text-textColor">seriesName</th>
              <th className="text-start">episode</th>
              <th className="text-start">category</th>
              <th className="text-start">price</th>
              <th className="text-start">img</th>
            </tr>
          </thead>
          <tbody className="h-[20px] overflow-y-scroll noScrollbar">
            {data?.map((val) => {
              return (
                <tr>
                  <td className="text-start">{val?.seriesName}</td>
                  <td className="text-start">{val?.episode}</td>
                  <td className="text-start">{val?.category}</td>
                  <td className="text-start">{val?.price}</td>
                  <td className="text-start">
                    <img
                      src={val?.img}
                      className="w-[48px] h-[48px] rounded-full object-cover"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        </div>
      </Layout>
    </>
  );
};

export default DataGrid;
