import React from "react";
import { cn } from "../lib/utils";

const Button = ({ text, className, onClick, disabled = false }) => {
  return (
    <button
      type="submit"
      className={cn(
        "buttonBg text-white text-xl font-semibold rounded-[10px] py-4",
        className
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default Button;
