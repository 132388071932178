import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import FileUpload from "../From/FileUpload";
import SelectBox from "../From/SelectBox";
import InputWithIcon from "../From/InputWithIcon";
import Button from "../Button";
import { GenreList, updateSeriesData } from "../../../Action/AdminAction";
import { toast } from "react-toastify";
import { IoClose } from "react-icons/io5";
import { useFormik } from "formik";
import { AddSeriesSchema } from "../../lib/schema";

const EditSeriesModal = ({ openModal, closeModal }) => {
  const [bannerImage, setBannerImage] = useState("");
  const [showImage, setShowImage] = useState(true);
  const [categoryData, setCategoryData] = useState();
  const [loader, setLoader] = useState(false);
  const formik = useFormik({
    initialValues: {
      series_name: "",
      price: "",
      category: "",
      series_desc: "",
    },
    validationSchema: AddSeriesSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      setLoader(true)
      formData.append("series_id", openModal?.data?._id);
      formData.append("series_image", bannerImage);
      formData.append("series_name", values?.series_name);
      formData.append("price", values?.price);
      formData.append("category", values?.category);
      formData.append("series_desc", values?.series_desc);

      const response = await updateSeriesData(formData);

      if (response?.data?.ResponseCode == 1 && response?.data?.Result == true) {
        toast.success(response?.data?.ResponseMsg);
        setLoader(false);
        setBannerImage();
        setShowImage(true);
        closeModal();
      } else {
        toast.error(response?.data?.ResponseMsg);
        setLoader(false);
      }
    },
  });

  const getCategoryListData = async () => {
    const response = await GenreList();
    response &&
      response?.data &&
      response?.data?.data &&
      setCategoryData(response?.data?.data?.data);
  };

  useEffect(() => {
    getCategoryListData();
  }, []);

  useEffect(() => {
    formik.values.series_name = openModal?.data?.series_name;
    formik.values.series_desc = openModal?.data?.series_desc;
    formik.values.price = openModal?.data?.price;
    formik.values.category = openModal?.data?.category;
    // setBannerImage(rowData?.series_image);
  }, [openModal?.data]);
  return (
    <Modal
      title="Edit Series"
      openModal={openModal?.open}
      closeModal={closeModal}
    >
      <form onSubmit={formik.handleSubmit} className="overflow-y-scroll h-full">
        <div className="space-y-4 px-5 pt-3 pb-5">
          {openModal?.data?.series_image && showImage ? (
            <div className="relative w-full">
              <img
                src={openModal?.data?.series_image}
                alt="not found"
                className="w-full h-[300px] object-cover rounded-[10px]"
              />
              <div
                className="absolute w-[30px] h-[30px] flex items-center justify-center rounded-md bg-inputBG top-2 right-3 z-20"
                onClick={() => {
                  setShowImage(false);
                }}
              >
                <IoClose className="text-white text-xl font-semibold" />
              </div>
            </div>
          ) : (
            ""
          )}
          {!showImage && (
            <FileUpload
              label="Upload Banner"
              onChange={(e) => {
                setBannerImage(e.target.files[0]);
              }}
              files={bannerImage}
            />
          )}
          <InputWithIcon
            placeholder="Series Name"
            name="series_name"
            onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            touched={formik.touched.series_name}
            error={formik.errors.series_name}
            value={formik.values.series_name}
          />
          <div className="grid grid-cols-2 gap-3">
            <InputWithIcon
              type="number"
              placeholder="Price"
              name="price"
              onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              touched={formik.touched.price}
              error={formik.errors.price}
              value={formik.values.price}
            />
            <SelectBox
              label="Branch Location"
              name="category"
              defaultOption="Select category"
              onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              touched={formik.touched.category}
              error={formik.errors.category}
              value={formik.values.category}
              options={categoryData}
            />
          </div>
          <div className="flex flex-col">
            <textarea
              name="series_desc"
              className={`w-full text-white  rounded-[15px] bg-inputBG shadow-3xl outline-none h-[150px] px-4 py-3 ${
                formik.errors.series_desc &&
                formik.touched.series_desc && //handle errors
                "placeholder:text-red-400 caret-red-400 ring-1 ring-red-400 focus-visible:ring-red-400"
              }`}
              placeholder="Description"
              onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              value={formik.values.series_desc}
            ></textarea>
            {formik.errors.series_desc && formik.touched.series_desc ? (
              <div className="text-red-500 text-xs mt-1">
                {formik.errors.series_desc}
              </div>
            ) : null}
          </div>
        </div>
        <div className="border-t border-inputBG w-full px-5 py-4">
          {loader ? (
            <button className="buttonBg w-full text-white text-xl font-semibold rounded-[10px] py-3.5 flex items-center justify-center">
              <svg
                aria-hidden="true"
                role="status"
                class="inline w-6 h-6 me-3 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#838383"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
              <span className="text-lg font-medium text-white">Loading...</span>
            </button>
          ) : (
            <Button text="Save" className="w-full" />
          )}
        </div>
      </form>
    </Modal>
  );
};

export default EditSeriesModal;
