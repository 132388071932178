import React, { useState } from "react";
import { FaEyeSlash } from "react-icons/fa";
import { IoMdEye } from "react-icons/io";

const PasswordInput = ({
  placeholder,
  name,
  onChange,
  icon,
  value,
  touched,
  error,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleShowChange = (e) => {
    e.preventDefault();
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="items-center justify-center w-full  rounded-[15px]">
      <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          {icon}
        </div>
        <input
          type={isPasswordVisible ? "text" : "password"}
          name={name}
          value={value}
          onChange={onChange}
          className={`block w-full px-4 py-4 ps-12 text-white  rounded-[15px] bg-inputBG shadow-3xl ${
            touched &&
            error && //handle errors
            "placeholder:text-red-400 caret-red-400 ring-1 ring-red-400 focus-visible:ring-red-400"
          }`}
          placeholder={placeholder}
        />
        <div
          className="absolute z-10 inset-y-0 right-0 flex items-center pr-3 cursor-pointer "
          onClick={() => {
            setIsPasswordVisible(!isPasswordVisible);
          }}
        >
          <FaEyeSlash
            className={`
              text-textColor
              ${isPasswordVisible ? "w-0 h-0" : "w-6 h-6"}
            `}
          />
          <IoMdEye
            className={`
              text-textColor
             ${isPasswordVisible ? "w-6 h-6" : "w-0 h-0"} 
            `}
          />
        </div>
      </div>
      {touched && error ? (
        <div className="text-red-500 text-xs mt-1 text-start">{error}</div>
      ) : null}
    </div>
  );
};

export default PasswordInput;
