import React from "react";
import SideBarItem from "./SideBarItem";
import { ImageUrl } from "../../../config/ImageUrl";

const Sidebar = () => {
  return (
    <>
      <SideBarItem
        path="/episode"
        label="Dashboard"
        icon={<img src={`${ImageUrl}assets/icons/home.svg`} alt="home icon" />}
        activeIcon={
          <img
            src={`${ImageUrl}assets/icons/homeActive.svg`}
            alt="home active icon"
          />
        }
      />
      <SideBarItem
        path="/discover"
        label="Discover"
        icon={
          <img src={`${ImageUrl}assets/icons/discover.svg`} alt="home icon" />
        }
        activeIcon={
          <img
            src={`${ImageUrl}assets/icons/discoverActive.svg`}
            alt="home active icon"
          />
        }
      />
      <SideBarItem
        path="/plan"
        label="Plan"
        icon={<img src={`${ImageUrl}assets/icons/plan.svg`} alt="home icon" />}
        activeIcon={
          <img
            src={`${ImageUrl}assets/icons/planActive.svg`}
            alt="home active icon"
          />
        }
      />
      <SideBarItem
        path="/category"
        label="Category"
        icon={
          <img src={`${ImageUrl}assets/icons/category.svg`} alt="home icon" />
        }
        activeIcon={
          <img
            src={`${ImageUrl}assets/icons/categoryActive.svg`}
            alt="home active icon"
          />
        }
      />
    </>
  );
};

export default Sidebar;
