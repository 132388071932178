import { createContext, useContext, useState } from "react";

const SearchingContext = createContext();

export const SearchingProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState("");
  return (
    <SearchingContext.Provider value={{ searchQuery, setSearchQuery }}>
      {children}
    </SearchingContext.Provider>
  );
};

export const SearchDataChange = () => useContext(SearchingContext);
