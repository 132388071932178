import React from "react";
import { Link, useLocation } from "react-router-dom";

const SideBarItem = ({ path, label, icon, activeIcon }) => {
  const { pathname } = useLocation();
  return (
    <>
      <Link to={path} className={`flex  w-full`}>
        <div
          className={`flex gap-5 w-full items-center px-3 my-2 py-3  text-xl  ${
            pathname?.startsWith(path)
              ? "text-[#F45944] font-bold"
              : "text-textColor font-medium"
          }`}
        >
          {pathname?.startsWith(path) ? (
            <div className="w-[4px] h-full bg-[#F45944] rounded-full"></div>
          ) : (
            <div></div>
          )}

          <div className="flex items-center gap-2">
            {pathname?.startsWith(path) ? (
              <div className="">{activeIcon}</div>
            ) : (
              <div className="">{icon}</div>
            )}

            <span className="p-0 m-0">{label}</span>
          </div>
        </div>
      </Link>
    </>
  );
};

export default SideBarItem;

// ${
//     pathname?.includes(path)
//       ? "bg-white rounded-xl  text-[#383F90] "
//       : "text-white bg-mainBG"
//   }
