import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import ButtonIcon from "./ButtonIcon";
import { useNavigate } from "react-router-dom";

const BreadCrum = ({ openModal, back, title, btnText = "Add" }) => {
  const navigate = useNavigate();
  return (
    <div className="w-full flex justify-between items-center px-5 py-5">
      <div className="flex gap-3 items-center">
        {back && (
          <div
            className="w-[25px] h-[25px] flex items-center justify-center bg-white rounded-lg"
            onClick={() => {
              navigate(-1);
            }}
          >
            <BsArrowLeft className="text-[black]" />
          </div>
        )}

        <p className="md:text-2xl text-[22px] font-bold text-white capitalize">
          {title}
        </p>
      </div>
      <div
        className={`${btnText === "Add New" && "min-w-[138px]"} ${
          btnText === "Add" ? "min-w-[110px]" : "min-w-[200px]"
        } `}
      >
        <ButtonIcon
          text={btnText}
          className="w-full"
          icon={<FiPlus className="text-[#F45944] font-medium" />}
          onClick={openModal}
        />
      </div>
    </div>
  );
};

export default BreadCrum;
