import axios from "axios";
import { BASE_NAME, BASEURL } from "../config/BaseUrl";

const token = localStorage.getItem("token");

export const adminLogin = async (payload) => {
  try {
    const response = await axios.post(`${BASEURL}/admin/login`, payload);
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const forgetAdminPassword = async (payload) => {
  try {
    const response = await axios.post(
      `${BASEURL}/admin/forgotPassword`,
      payload
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const verifyOtp = async (payload) => {
  try {
    const response = await axios.post(`${BASEURL}/admin/verifyOTP`, payload);
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const ResetAdminPassword = async (payload) => {
  try {
    const response = await axios.post(
      `${BASEURL}/admin/resetPassword`,
      payload
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const addNewSeries = async (payload) => {
  try {
    const response = await axios.post(`${BASEURL}/admin/addSeries`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const updateSeriesData = async (payload) => {
  try {
    const response = await axios.post(
      `${BASEURL}/admin/seriesUpdate`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const getSeriesList = async (payload) => {
  try {
    const response = await axios.post(`${BASEURL}/admin/seriesList`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if ((response?.data?.ResponseCode == 0 || response?.data?.ResponseCode == 6) && response?.data?.Result == false) {
      localStorage.removeItem("token");
      localStorage.removeItem("category_name");
      window.location = BASE_NAME;
    }
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const deleteSeriesData = async (payload) => {
  try {
    const response = await axios.post(
      `${BASEURL}/admin/seriesDelete`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const addNewEpisodeToSeries = async (payload) => {
  try {
    const response = await axios.post(`${BASEURL}/admin/addEpisode`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const getEpisodesBySeries = async (payload) => {
  try {
    const response = await axios.post(`${BASEURL}/admin/episodeList`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if ((response?.data?.ResponseCode == 0 || response?.data?.ResponseCode == 6) && response?.data?.Result == false) {
      localStorage.removeItem("token");
      localStorage.removeItem("category_name");
      window.location = BASE_NAME;
    }
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const deleteEpisodeById = async (payload) => {
  try {
    const response = await axios.post(
      `${BASEURL}/admin/episodeDelete`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const CategoryList = async (payload = {}) => {
  try {
    const response = await axios.post(
      `${BASEURL}/admin/categoryList`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if ((response?.data?.ResponseCode == 0 || response?.data?.ResponseCode == 6) && response?.data?.Result == false) {
      localStorage.removeItem("token");
      localStorage.removeItem("category_name");
      window.location = BASE_NAME;
    }
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const addNewCategory = async (payload) => {
  try {
    const response = await axios.post(`${BASEURL}/admin/addCategory`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const addNewGenre = async (payload) => {
  try {
    const response = await axios.post(`${BASEURL}/admin/addGenre`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const GenreList = async (payload) => {
  try {
    const response = await axios.post(
      `${BASEURL}/admin/listGenre`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if ((response?.data?.ResponseCode == 0 || response?.data?.ResponseCode == 6) && response?.data?.Result == false) {
      localStorage.removeItem("token");
      localStorage.removeItem("category_name");
      window.location = BASE_NAME;
    }
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const deleteGenre = async (payload) => {
  try {
    const response = await axios.post(`${BASEURL}/admin/deleteGenre`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const updateGenre = async (payload) => {
  try {
    const response = await axios.post(`${BASEURL}/admin/updateGenre`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const getSeriesListByCategory = async (payload) => {
  try {
    const response = await axios.post(
      `${BASEURL}/admin/categoryViseSeries`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if ((response?.data?.ResponseCode == 0 || response?.data?.ResponseCode == 6) && response?.data?.Result == false) {
      localStorage.removeItem("token");
      localStorage.removeItem("category_name");
      window.location = BASE_NAME;
    }
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const addNewSeriesToCategory = async (payload) => {
  try {
    const response = await axios.post(
      `${BASEURL}/admin/newSeriesAdd`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const planList = async (payload) => {
  try {
    const response = await axios.post(`${BASEURL}/admin/planList`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if ((response?.data?.ResponseCode == 0 || response?.data?.ResponseCode == 6) && response?.data?.Result == false) {
      // localStorage.removeItem("token");
      // localStorage.removeItem("category_name");
      // window.location =BASE_NAME;
    }
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const addNewPlan = async (payload) => {
  try {
    const response = await axios.post(`${BASEURL}/admin/addPlan`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const planRemove = async (payload) => {
  try {
    const response = await axios.post(`${BASEURL}/admin/planDelete`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const planUpdate = async (payload) => {
  try {
    const response = await axios.post(`${BASEURL}/admin/planUpdate`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const updateCategory = async (payload) => {
  try {
    const response = await axios.post(
      `${BASEURL}/admin/updateCategory`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const deleteCategoryData = async (payload) => {
  try {
    const response = await axios.post(
      `${BASEURL}/admin/deleteCategory`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const updateEpisodeData = async (payload) => {
  try {
    const response = await axios.post(
      `${BASEURL}/admin/episodeUpdate`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};


export const deleteSeriesDataFromCategory = async (payload) => {
  try {
    const response = await axios.post(`${BASEURL}/admin/deleteSeriesFromCat`, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  } catch (error) {
    return error?.response
  }
}