import React from 'react'
import DataTable from 'react-data-table-component'

const customStyles = {
    table: {
        style: {
            color: 'white',
            height: "100%",
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingBottom: '5px',
            backgroundColor: '#020102',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',
            position: "relative"

        }
    },
    rowgroup: {
        style: {
            border: 'none'
        }
    },
    cells: {
        style: {
            borderBottomStyle: 'none',
            minHeight: '70px',
            backgroundColor: '#020102',
            color: 'white'
        }
    },
    rows: {
        style: {
            backgroundColor: 'transparent',
            fontSize: '16px',
            fontWeight: '400',
            color: 'white',
            //   border: "none",
            //   borderBottomStyle: "none",
            '&:not(:last-of-type)': {
                borderBottomStyle: '0.5px',
                borderBottomColor: '#271E27'
            }
        }
    },
    headRow: {
        style: {
            minHeight: '70px',
            borderStyle: 'none',
            backgroundColor: '#131313',
            color: '#838383',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',
            fontSize: '18px',
            fontWeight: '500'
        }
    },
}

const data = [
    {
        id: "1",
        name: "xyz",
        lname: "patel"
    },
    {
        id: "2",
        name: "pqr",
        lname: "patel"
    }
]

const columns = [
    {
        name: "Business",
        selector: row => <div className='flex gap-2 items-center'>
            <p className='text-white text-lg font-medium'>{row.name}</p>
        </div>
    },
    {
        name: "Last Name",
        selector: row => <div className='flex gap-2 items-center'>
            <p className='text-white text-lg font-medium'>{row.lname}</p>
        </div>
    },
    // {
    //     name: "Action",
    //     width: "150px",
    //     cell: (row) => (
    //         <div className='flex gap-3'>
    //             <EyeIcon onClick={() => { handleView(row?.id) }} />
    //             <EditIcon
    //                 onClick={() => {
    //                     setBlogModal({ data: row, open: true, mode: DIALOG_MODE.edit })
    //                 }}
    //             />
    //             <TrashIcon
    //                 onClick={() => {
    //                     setDeleteModal({ data: row, open: true, mode: DIALOG_MODE.delete })
    //                 }}
    //             />

    //         </div>
    //     ),
    // },

]

const Tabel = () => {
    return (
        <>
            <div className='h-full flex flex-col justify-between bg-[#020102] rounded-[15px] relative overflow-y-scroll'>
                <DataTable data={data} columns={columns} customStyles={customStyles} />
            </div>
        </>
    )
}

export default Tabel