import "./App.css";
import Login from "./pages/Login";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import ForgetPassword from "./pages/ForgetPassword";
import VerifyOtp from "./pages/VerifyOtp";
import ResetPassword from "./pages/ResetPassword";
import Dashboard from "./pages/Dashboard";
import SeriesDetails from "./pages/SeriesDetails";
import Discover from "./pages/Discover";
import Plan from "./pages/Plan";
import ViewAllShow from "./pages/ViewAllShow";
import { SeriesProvider } from "./components/context/SeriesContext";
import FullFeaturedCrudGrid from "./components/common/Table/DataGrid";
import ProtectedRoute from "./route/ProtectRoute";
import Category from "./pages/Category";
import { CategoryProvider } from "./components/context/CategoryContext";
import Tabel from "./pages/Tabel";
import { SearchingProvider } from "./components/context/SearchQueryContext";

function App() {
  return (
    <div className="App">
      <SeriesProvider>
        <CategoryProvider>
          <SearchingProvider>
            <BrowserRouter basename="/">
              <Routes>
                <Route
                  index
                  element={
                    <ProtectedRoute redirectAuthenticated={true}>
                      <Login />
                    </ProtectedRoute>
                  }
                />
                <Route path="/forget-password" element={<ForgetPassword />} />
                <Route path="/verify-otp" element={<VerifyOtp />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/episode" element={<Outlet />}>
                  <Route index element={<ProtectedRoute> <Dashboard /> </ProtectedRoute>} />
                  <Route path=":id" element={<ProtectedRoute> <SeriesDetails /> </ProtectedRoute>} />
                </Route>
                <Route path="/discover" element={<Outlet />}>
                  <Route index element={<ProtectedRoute> <Discover /> </ProtectedRoute>} />
                  <Route path=":type" element={<ProtectedRoute> <ViewAllShow /> </ProtectedRoute>} />
                </Route>
                <Route path="plan" element={<ProtectedRoute><Plan /> </ProtectedRoute>} />
                <Route path="category" element={<ProtectedRoute><Category /></ProtectedRoute>} />
                <Route path="/tabel" element={<Tabel />} />
                {/* <Route path="/episodes/:id" element={<SeriesDetails />} /> */}
              </Routes>
            </BrowserRouter>
          </SearchingProvider>
        </CategoryProvider>
      </SeriesProvider>
    </div>
  );
}

export default App;
