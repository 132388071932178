import React, { useState } from "react";
import Sidebar from "./Sidebar";
import SearchBar from "../From/SearchBar";
import { IoIosArrowDown } from "react-icons/io";
import { ImageUrl } from "../../../config/ImageUrl";
import LogoutModal from "../Modal/LogoutModal";

const Layout = ({ title, children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <>
      <div className="flex h-screen  overflow-hidden bg-[url('../public/assets/images/MainBg.png')] bg-cover bg-center">
        {/* Sidebar */}
        <div
          className={`fixed flex justify-between  inset-y-0 left-0 z-30 w-64  shadow-lg transform transition-transform duration-300 md:relative md:translate-x-0 ${
            sidebarOpen ? "translate-x-0 bg-mainBG" : "-translate-x-full"
          }`}
        >
          <div className="flex flex-col  border-opacity-20 w-full">
            <div className="py-5 flex-grow px-5">
              <div className="pt-5 pb-7 border-b flex justify-center border-inputBG ">
                <img
                  src={`${ImageUrl}assets/images/PLOTPEAK.png`}
                  alt="main logo"
                />
              </div>
              <nav className="mt-4 flex-grow border-white ">
                <Sidebar />
              </nav>
            </div>
            {/* Logout Button */}
            <div className="px-4 flex flex-col">
              <div className="h-[1px] w-full bg-inputBG"></div>
              <div className=" py-6  flex justify-center gap-4">
                <img
                  src={`${ImageUrl}assets/icons/logout.svg`}
                  alt="not found"
                />
                <button
                  className="text-textColor text-xl font-semibold"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Sign Out
                </button>
              </div>
            </div>
          </div>
          <div className="h-full w-[1px] sidebarBorder"></div>
        </div>

        {/* Main Content Wrapper */}
        <div className="flex-1 flex flex-col overflow-hidden h-dvh">
          {/* Header */}
          <div className=" px-4 xs:px-8 py-6 flex flex-col gap-8 border-b  border-inputBG">
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <button
                  className="text-white max-md:mr-2 focus:outline-none md:hidden"
                  onClick={toggleSidebar}
                >
                  ☰
                </button>
                <h1 className="lg:text-2xl md:text-xl text-lg font-semibold text-white md:ml-5 ml-1 text-start">
                  {title}
                </h1>
              </div>
              <div className="flex items-center  gap-x-4 xs:gap-10">
                <SearchBar placeholder="Search anything..." />
                {/* <div className="flex gap-x-3 xs:gap-5 items-center">
                  <div className="w-10 h-10 rounded-full">
                    <img
                      src={`${ImageUrl}assets/images/profile.png`}
                      alt="profile"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-1 overflow-auto  noScrollbar bg-black">
            <main className="flex-1 p-4" onClick={() => setSidebarOpen(false)}>
              {children}
            </main>
          </div>
        </div>
      </div>

      <LogoutModal
        openModal={open}
        closeModal={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

export default Layout;
