import React from "react";
import { LiaSignOutAltSolid } from "react-icons/lia";
import { Dialog, DialogContent, DialogDescription } from "./ModalComponents";
import Button from "../Button";
import { useNavigate } from "react-router-dom";

const LogoutModal = ({ openModal, closeModal }) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("category_name");
    localStorage.removeItem("email");
    closeModal();
    navigate("/");
  };
  return (
    <>
      <Dialog open={openModal} onOpenChange={closeModal}>
        <DialogContent>
          <DialogDescription>
            <div className="px-5 py-7 space-y-5 flex flex-col items-center">
              <div className="flex justify-center">
                <div className="w-[141px] h-[141px] flex items-center justify-center rounded-full bg-inputBG shadow-3xl">
                  <LiaSignOutAltSolid className="text-7xl font-bold text-white" />
                </div>
              </div>
              <div className="space-y-2">
                <p className="text-2xl font-medium text-white text-center">
                  Sign Out
                </p>
                <p className="text-textColor text-xl font-normal text-center">
                  Are you sure you want to Sign Out?
                </p>
              </div>
              <div className="grid grid-cols-2 gap-4 w-full border-t border-inputBG pt-4">
                <button
                  className="w-full bg-textColor shadow-3xl text-white  text-xl font-normal rounded-[10px]"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <Button
                  text="Sign Out"
                  className="w-full"
                  onClick={handleLogout}
                />
              </div>
            </div>
          </DialogDescription>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LogoutModal;
