import { createContext, useContext, useState } from "react";

const CategoryContext = createContext();

export const CategoryProvider = ({ children }) => {
    const [seriesOpen, setSeriesOpen] = useState(false);
    return (
        <CategoryContext.Provider value={{ seriesOpen, setSeriesOpen }}>
            {children}
        </CategoryContext.Provider>
    )
}

export const NewCategoryData = () => useContext(CategoryContext)