import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { SelectedSeriesData } from "../../context/SeriesContext";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./Table";

const DataTable = ({ data, columns, center, onRowClick }) => {
  const { selectedSeries, setSelectedSeries } = SelectedSeriesData();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleRowClick = (rowData) => {
    setSelectedSeries(rowData);
    onRowClick && onRowClick(rowData);
  };

  return (
    <div className="p-4 h-[85vh]">
      <div className="w-full max-w-full overflow-x-auto bg-[#0c0b0c] shadow-3xl rounded-2xl p-4">
        <Table className="min-w-full">
          <TableHeader className="bg-inputBG shadow-3xl rounded-[10px] sticky top-0">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="border-none">
                {headerGroup.headers.map((header) => (
                  <TableHead
                    key={header.id}
                    className={`py-5 text-textColor text-base text-medium text-start px-2 first:rounded-l-xl last:rounded-r-xl`}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  className="border-[#271E27]"
                  onClick={() => handleRowClick(row.original)}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      className={`py-4 px-4 text-lg	text-white font-medium ${
                        cell?.column?.id === "delete"
                          ? "text-right"
                          : "text-start"
                      }`}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center text-white text-xl font-medium"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default DataTable;
