import React, { useCallback, useEffect, useState } from "react";
import Layout from "../components/common/Layout/Layout";
import BreadCrum from "../components/common/BreadCrum";
import { ImageUrl } from "../config/ImageUrl";
import {
  GenreList,
  addNewGenre,
  deleteGenre,
  updateGenre,
} from "../Action/AdminAction";
import InputWithIcon from "../components/common/From/InputWithIcon";
import { toast } from "react-toastify";
import Modal from "../components/common/Modal/Modal";
import Button from "../components/common/Button";
import DeleteModal from "../components/common/Modal/DeleteModal";
import { SearchDataChange } from "../components/context/SearchQueryContext";
import debounce from "lodash/debounce";
import { useFormik } from "formik";
import { categorySchema } from "../components/lib/schema";

const Category = () => {
  const { searchQuery, setSearchQuery } = SearchDataChange();
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(false);
  const [categoryID, setCategoryID] = useState();
  const [categoryData, setCategoryData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const [hover, setHover] = useState();

  const formik = useFormik({
    initialValues: {
      category: "",
    },
    validationSchema: categorySchema,
    onSubmit: (values) => {
      categoryID
        ? updateCategoryData(values?.category)
        : AddNewCategoryData(values?.category);
    },
  });

  const getCategoryData = async () => {
    const response = await GenreList({
      search: search,
    });
    if (response && response?.data && response?.data?.data) {
      setCategoryData(response?.data?.data?.data);
      setLoader(false);
    }
  };

  useEffect(() => {
    getCategoryData();
  }, [search]);

  const AddNewCategoryData = async (values) => {
    const formData = new FormData();

    setModalLoader(true);
    formData.append("category_name", values);
    const response = await addNewGenre(formData);

    if (response?.data?.ResponseCode == 1 && response?.data?.Result == true) {
      toast.success(response?.data?.ResponseMsg);
      setModalLoader(false);
      localStorage.setItem(
        "category_name",
        response?.data?.data?.category_name
      );
      formik.handleSubmit();
      setOpen(false);
      getCategoryData();
    } else {
      toast.error(response?.data?.ResponseMsg);
      setModalLoader(false);
    }
  };

  const updateCategoryData = async (values) => {
    const editData = new FormData();
    setModalLoader(true);
    editData.append("category_id", categoryID);
    editData.append("category_name", values);
    const response = await updateGenre(editData);

    if (response?.data?.ResponseCode == 1 && response?.data?.Result == true) {
      toast.success(response?.data?.ResponseMsg);
      formik.handleSubmit();
      setCategoryID("");
      setEditCategory(false);
      setModalLoader(false);
      getCategoryData();
    } else {
      toast.error(response?.data?.ResponseMsg);

      setModalLoader(false);
    }
  };

  const handleDeleteCategory = async () => {
    setDeleteLoader(true);
    const deleteData = new FormData();

    deleteData.append("category_id", categoryID);

    const response = await deleteGenre(deleteData);
    if (response?.data?.ResponseCode === 1 && response?.data?.Result == true) {
      toast.success(response?.data?.ResponseMsg);
      setDeleteLoader(false);
      setCategoryID("");
      setDeleteCategory(false);
      getCategoryData();
    } else {
      toast.error(response?.data?.ResponseMsg);
      setDeleteLoader(false);
    }
  };

  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearch(query);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery]);

  return (
    <>
      <Layout title="Category">
        <BreadCrum
          back={false}
          btnText="New Category"
          openModal={() => {
            setOpen(true);
          }}
        />
        <div className="grid lg:grid-cols-4 grid-cols-3 gap-5">
          {!loader ? (
            <>
              {categoryData && categoryData?.length > 0 ? (
                categoryData?.map((val, index) => {
                  return (
                    <div
                      key={index}
                      className="relative cursor-pointer"
                      onMouseEnter={() => {
                        setHover(index);
                      }}
                      onMouseLeave={() => {
                        setHover();
                      }}
                    >
                      <div className="w-full bg-inputBG shadow-4xl py-6 rounded-[10px] mainCategory ">
                        <p className="text-xl font-medium text-textColor capitalize">
                          {val?.category_name}
                        </p>
                      </div>
                      {hover === index && (
                        <div className="flex gap-10 justify-center absolute top-0 w-full py-7 rounded-[10px] bg-inputBG/70">
                          <img
                            className="cursor-pointer"
                            src={`${ImageUrl}assets/icons/editIcon.svg`}
                            alt="edit"
                            onClick={() => {
                              setEditCategory(true);
                              setCategoryID(val?._id);
                              formik.setFieldValue(
                                "category",
                                val?.category_name
                              );
                            }}
                          />
                          <img
                            className="cursor-pointer"
                            src={`${ImageUrl}assets/icons/trash.svg`}
                            alt="trash"
                            onClick={() => {
                              setDeleteCategory(true);
                              setCategoryID(val?._id);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                })
              ) : (
                <div className="2xl:col-span-5 lg:col-span-4 md:col-span-3 col-span-2 ">
                  <p className="text-base font-medium text-white">
                    No Data Found
                  </p>
                </div>
              )}
            </>
          ) : (
            <div
              role="status"
              className="2xl:col-span-5 lg:col-span-4 md:col-span-3 col-span-2 flex justify-center"
            >
              <svg
                aria-hidden="true"
                class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          )}
        </div>

        {/* add category modal start */}
        <Modal
          title="Add New Category"
          openModal={open}
          closeModal={() => {
            setOpen(false);
            formik.resetForm();
          }}
        >
          <div className="px-5 pt-3 pb-5">
            <InputWithIcon
              type="text"
              placeholder="Category Name"
              name="category"
              onChange={formik.handleChange}
              value={formik.values.category}
              error={formik.errors.category}
              touched={formik.touched.category}
            />
          </div>
          <div className="border-t border-inputBG w-full px-5 py-4">
            <Button
              text={
                modalLoader ? (
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-6 h-6 text-white animate-spin -mt-2.5"
                    viewBox="0 0 100 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#838383"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                ) : (
                  "Create"
                )
              }
              className="w-full"
              onClick={formik.handleSubmit}
            />
          </div>
        </Modal>
        {/* end */}
        {/* update category modal start */}
        <Modal
          title="Edit Genre"
          openModal={editCategory}
          closeModal={() => {
            setEditCategory(false);
            formik.resetForm();
          }}
        >
          <div className="px-5 pt-3 pb-5">
            <InputWithIcon
              type="text"
              placeholder="Category Name"
              name="category"
              onChange={formik.handleChange}
              value={formik.values.category}
              error={formik.errors.category}
              touched={formik.touched.category}
            />
          </div>
          <div className="border-t border-inputBG w-full px-5 py-4">
            <Button
              text={
                modalLoader ? (
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-6 h-6 text-white animate-spin -mt-2.5"
                    viewBox="0 0 100 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#838383"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                ) : (
                  "Save"
                )
              }
              className="w-full"
              onClick={formik.handleSubmit}
            />
          </div>
        </Modal>
        {/* end */}
        {/* delete category modal start */}
        <DeleteModal
          openModal={deleteCategory}
          closeModal={() => {
            setDeleteCategory(false);
          }}
          handleSubmit={handleDeleteCategory}
          title="delete category"
          loader={deleteLoader}
        />
        {/* delete category modal end */}
      </Layout>
    </>
  );
};

export default Category;
