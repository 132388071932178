import React, { createContext, useState, useContext } from "react";

const SeriedContext = createContext();

export const SeriesProvider = ({ children }) => {
  const [selectedSeries, setSelectedSeries] = useState();
  const [selectedPlan, setSelectedPlan] = useState();
  return (
    <SeriedContext.Provider
      value={{
        selectedSeries,
        setSelectedSeries,
        selectedPlan,
        setSelectedPlan,
      }}
    >
      {children}
    </SeriedContext.Provider>
  );
};

export const SelectedSeriesData = () => useContext(SeriedContext);
