import React from "react";
import { cn } from "../lib/utils";

const ButtonIcon = ({ text, className, onClick, icon }) => {
  return (
    <button
      className={cn(
        "buttonBg text-white text-xl font-semibold rounded-[10px] py-2 flex items-center justify-center gap-3",
        className
      )}
      onClick={() => {
        onClick();
      }}
    >
      <div className="flex items-center justify-center bg-white w-[29px] h-[29px] rounded-full">
        {icon}
      </div>
      <span> {text}</span>
    </button>
  );
};

export default ButtonIcon;
