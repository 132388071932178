import React from "react";
import { IoMdSearch } from "react-icons/io";
import { SearchDataChange } from "../../context/SearchQueryContext";
import { useLocation } from "react-router-dom";

const SearchBar = ({ placeholder }) => {
  const { searchQuery, setSearchQuery } = SearchDataChange();
  const { pathname } = useLocation();

  const handleNumberOnly = (e) => {
    if (pathname === "/plan") {
      if (e.key.toLowerCase() === "e" || e.key === "+" || e.key === "-") {
        e.preventDefault();
      }
    }
  };

  return (
    <div className="relative">
      <div className="absolute  inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
        <IoMdSearch className="text-textColor" />
      </div>
      <input
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
        onKeyDown={handleNumberOnly}
        type={pathname === "/plan" ? "number" : "text"}
        id="search"
        className="min-w-[348px]
         px-4 py-2.5 ps-10 text-sm  rounded-[15px] bg-inputBG shadow-3xl placeholder:text-textColor text-textColor"
        placeholder={placeholder}
      />
    </div>
  );
};

export default SearchBar;
