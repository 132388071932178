import React from "react";
import { ImageUrl } from "../../../config/ImageUrl";

const FileUpload = ({ label, onChange, name, files }) => {
  const size = files && Object.keys(files).length;

  return (
    <>
      <label className="w-full flex flex-col items-center px-4 py-8 bg-inputBG shadow-3xl rounded-[10px]">
        <img
          src={`${ImageUrl}assets/icons/document-upload.svg`}
          alt="upload banner"
        />

        <span className="mt-2 text-base font-medium leading-normal text-textColor">
          {!files ? label : `${size} file selected`}
        </span>
        <input
          type="file"
          className="hidden"
          // value={value?.name}
          name={name}
          onChange={onChange}
          multiple
        />
      </label>
    </>
  );
};

export default FileUpload;
