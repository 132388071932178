import React from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "./ModalComponents";
import { IoMdClose } from "react-icons/io";

const Modal = ({ openModal, closeModal, title, children }) => {
  return (
    <>
      <Dialog open={openModal} onOpenChange={closeModal}>
        <DialogContent className="!bg-[#0c0b0b] ">
          <DialogHeader>
            {/* <div className="flex flex-col "> */}
            <div className="flex justify-between items-center border-b border-inputBG w-full px-4 py-4">
              <h1 className="text-white text-xl font-semibold">{title}</h1>
              <div
                className="bg-inputBG w-10 h-10 rounded-lg flex justify-center items-center shadow-3xl"
                onClick={closeModal}
              >
                <IoMdClose className="text-white" />
              </div>
              {/* </div> */}
              {/* <div className="max-w-[400px] h-[1px] sidebarBorder"></div> */}
            </div>
          </DialogHeader>
          <DialogDescription>{children}</DialogDescription>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Modal;
