import React from "react";

const InputWithIcon = ({
  icon,
  placeholder,
  type,
  value,
  onChange,
  name,
  touched,
  error,
  onBlur,
}) => {
  return (
    <div className="items-center justify-center w-full  rounded-[15px] outline-none">
      {/* <label
      for="search"
      class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
    >
      {label}
    </label> */}
      <div className="relative">
        <div className="absolute  inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          {icon}
        </div>

        <input
          type={type}
          value={value}
          name={name}
          // min={0}
          id="search"
          onChange={onChange}
          onBlur={onBlur}
          className={`block w-full px-4 py-4 ${
            icon !== undefined ? "ps-12" : ""
          } ${
            touched &&
            error && //handle errors
            "placeholder:text-red-400 caret-red-400 ring-1 ring-red-400 focus-visible:ring-red-400"
          }, text-white  rounded-[15px] bg-inputBG shadow-3xl outline-none`}
          placeholder={placeholder}
        />
      </div>
      {touched && error ? (
        <div className="text-red-500 text-xs mt-1 text-start">{error}</div>
      ) : null}
    </div>
  );
};

export default InputWithIcon;
