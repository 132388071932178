import React from "react";

const OTPInput = ({ id, prevElement, name, nextElement, onChange }) => {
  // const focusNextInput = (e) => {
  //   const { value } = e.target;
  //   if (value.length === 0 && prevElement) {
  //     document.getElementById(prevElement).focus();
  //   } else if (value.length === 1 && nextElement) {
  //     document.getElementById(nextElement).focus();
  //   }
  // };

  const focusNextInput = (e) => {
    return e.target.value.length === 0
      ? prevElement && document.getElementById(prevElement).focus()
      : nextElement && document.getElementById(nextElement).focus();
  };

  return (
    <input
      className="w-full max-w-[90px] flex-1  h-[60px] py-3 text-xl text-textColor bg-inputBG shadow-3xl text-center placeholder:text-center  rounded-xl outline-none"
      id={id}
      maxLength={1}
      name={name}
      onKeyUp={focusNextInput}
      onChange={onChange}
    />
    // <input
    //   className="w-[80px] h-[60px] rounded-xl outline-none bg-inputBG shadow-3xl"
    //   id={id}
    //   maxLength={1}
    //   name={name}
    //   onKeyUp={focusNextInput}
    //   onChange={onChange}
    // />
  );
};

export default OTPInput;
