import React, { useState } from "react";
import Button from "../components/common/Button";
import { Link, useNavigate } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";
import PasswordInput from "../components/common/From/PasswordInput";
import { ImageUrl } from "../config/ImageUrl";
import { ResetAdminPassword } from "../Action/AdminAction";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { resetPasswordSchema } from "../components/lib/schema";
import { values } from "lodash";
import md5 from "md5";

const ResetPassword = () => {
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      password: "",
      newpassword: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values) => {
      const email = localStorage.getItem("email");
      setLoader(true);
      const formData = new FormData();
      formData.append("email", email);
      formData.append("new_password", md5(values?.password));

      const response = await ResetAdminPassword(formData);

      if (response?.data?.ResponseCode == 1 && response?.data?.Result == true) {
        toast.success(response?.data?.ResponseMsg);
        setLoader(false);
        navigate("/");
      } else {
        toast.error(response?.data?.ResponseMsg);
        setLoader(false);
      }
    },
  });

  return (
    <>
      <div className="grid sm:grid-cols-2 grid-cols-1 justify-center sm:h-screen h-full items-center w-full min-h-screen bg-center	 bg-[url('../public/assets/images/MainBg.png')] bg-cover	">
        <div className=" flex flex-col items-center justify-center sm:py-0 py-10 space-y-10">
          <div className="overlay">
            <img
              className=" light-purple-filter"
              src="assets/images/resetPassword.png"
              alt="hand-logo"
            />
          </div>
        </div>
        <div className="w-full  flex flex-col items-center justify-center h-screen py-20 lg:pr-36 sm:pr-10 px-5">
          <div className="bg-[#100c0c] shadow-4xl w-full h-full rounded-2xl flex items-center justify-center">
            <form className="w-full" onSubmit={formik.handleSubmit}>
              <h2 className=" text-1xl font-semibold text-center text-white">
                Set New Password
              </h2>
              <div className=" flex justify-center">
                <p className="text-xl font-normal  text-textColor text-center  max-w-[334px]">
                  Your new password must be different from previously used
                  password.
                </p>
              </div>

              <div className="mt-8 flex flex-col gap-y-7 px-5 md:px-10 lg:px-10 2xl:px-20">
                <PasswordInput
                  placeholder=" New password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.password}
                  touched={formik.touched.password}
                  icon={
                    <img
                      src={`${ImageUrl}assets/icons/lock.svg`}
                      alt="password"
                    />
                  }
                />
                <div>
                  <PasswordInput
                    placeholder="Confirm password"
                    name="newpassword"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.newpassword}
                    touched={formik.touched.newpassword}
                    icon={
                      <img
                        src={`${ImageUrl}assets/icons/lock.svg`}
                        alt="password"
                      />
                    }
                  />
                </div>
                <Button
                  text={
                    loader ? (
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline w-6 h-6 text-white animate-spin -mt-2.5"
                        viewBox="0 0 100 100"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#838383"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                    ) : (
                      "Reset Password"
                    )
                  }
                  className="w-full"
                />
                <div className="flex justify-center mt-32">
                  <Link
                    className="flex items-center gap-3 text-textColor text-lg	font-normal "
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    <GoArrowLeft /> <span>Back to Sign In</span>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
