import React from "react";

const CheckBox = ({ name, image, onChange, value }) => {
  return (
    <>
      <div class="flex items-center justify-between space-x-3 border-b py-3 border-[#271E27]">
        <div class="flex gap-3 items-center">
          <img
            src={image}
            alt="user profile"
            className="object-cover w-[48px] h-[48px] rounded-full"
          />
          <p class="text-white text-lg   font-medium">{name}</p>
        </div>
        <input
          type="checkbox"
          name="series"
          value={value}
          class=" rounded h-[24px] w-[24px] accent-[#F45944] "
          onChange={onChange}
        />
      </div>
    </>
  );
};

export default CheckBox;
